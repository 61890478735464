import { createStore } from 'vuex'

export default createStore({
  state: {
    off: false, //显隐修改号码框
    openid:'',
    search:''
  },
  mutations: {
    getoff(state, data) {
      state.off = data;
      console.log(data, state.off);
    },
    getopenid(state, data) {
      state.openid = data;
      console.log(state.openid);
    },
    getsearch(state, data) {
      state.search = data;
     
    },
  },
  actions: {
  },
  modules: {
  }
})
