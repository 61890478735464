import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    alias:'/purchase/number',
    name: "number",
    meta: {
      name: "靓号限时抢购",
    },
    component: () => import("page/purchase/number.vue"),
  },
  {
    path: "/promotion",
   
    name: "promotion",
    meta: {
      name: "呼应",
    },
    component: () => import("page/purchase/promotion.vue"),
  },
  {
    path: "/purchase/combo",
    name: "combo",
    meta: {
      name: "靓号限时抢购",
    },
    component: () => import("page/purchase/combo.vue"),
  },

  {
    path: "/purchase/numbers",
    name: "numbers",
    meta: {
      name: "选号码",
    },
    component: () => import("page/purchase/numbers.vue"),
  },
  {
    path: "/purchase/order",
    name: "order",
    meta: {
      name: "订单",
    },
    component: () => import("page/purchase/order.vue"),
  },

  {
    path: "/purchase/paysuccess",
    name: "paysuccess",
    meta: {
      name: "支付成功",
    },
    component: () => import("page/purchase/paysuccess.vue"),
  },
  {
    path: "/purchase/trialNumber",
    name: "trialNumber",
    meta: {
      name: "选号码",
    },
    component: () => import("page/purchase/trialNumber.vue"),
  },
  {
    path: "/mansion",
    name: "trialNumber",
    meta: {
      name: "广贤公馆",
    },
    component: () => import("page/mansion.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: {
      name: "404",
    },
    component: () => import("page/404.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
