
import { openid } from "@/api/request.js";
import https from "@/utils/wxopenid.js";
import { ref ,watch} from "vue";
import { useStore } from "vuex";
import router from "./router";
export default {
  setup() {
    const store = useStore();
    const codeList = ref("");
    const openId = ref("");
    const APPID = "wxe83b3c17d14f292b";
    const currentUrl = window.location.href;
    console.log(window.location.href);
    const getWxCode = async () => {
      const code = https.getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      if (code == null || code === "") {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          APPID +
          "&redirect_uri=" +
          encodeURIComponent(currentUrl) +
          "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
      } else {
        // localStorage.setItem("wxCode", code); //取到code值
        // codeList.value = code;
        const { data } = await openid({
          code: code,
        });
        openId.value = data.openid;
        store.commit("getopenid", data.openid);

        console.log(data);
      }
    };
    watch(() => router.currentRoute.value,(newrot,old)=>{
       console.log(old,newrot,'lvjg watch');
       if(newrot.path=='/mansion'){
        return;
       }
    },{immediate:true})
    // 对浏览器的UserAgent进行正则匹配，不含有微信独有标识的则为其他浏览器
    var useragent: any = navigator.userAgent;
    console.log(useragent.match(/MicroMessenger/i), 122111);
    if (useragent.match(/MicroMessenger/i) != "MicroMessenger") {
      // alert("其他浏览器页面！");
      openId.value = "null";
      store.commit("getopenid", openId.value);
    } else {
      // alert("微信浏览器！");
      // getopenid();
      getWxCode();
    }
    return {};
  },
};
