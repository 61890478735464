// signatureUtil.js
import md5 from "js-md5";

export function signatureGenerate({ data, url, headers }:{ data:any, url:any, headers:any }) {
    // 参数签名 密钥 + 时间戳 + header参数 + url

    // 密钥
    let secret = Math.random().toString(36).substring(2)
    // 时间戳
    let timestamp = new Date().getTime()
    // token
    let token = headers.Authorization
    // post参数
    let dataStr = dataSerialize(data as any)
    // 生成签名
    let str = dataStr + "secret=" + secret + "&timestamp=" + timestamp + "&url=" + url
    console.log(dataStr, secret, 'lvjg sign --',);

    // const sign = md5(str)
    const sign = md5(dataStr + 'huyingav5g')

    return {
        signature: sign.toUpperCase(), // 将签名字母转为大写
        timestamp,
        secret
    }
}

// 参数排序
function dataSort(obj:any) {
    if (JSON.stringify(obj) == "{}" || obj == null) {
        return {}
    }
    let key = Object.keys(obj)?.sort()
    let newObj:any = {}
    for (let i = 0; i < key.length; i++) {
        newObj[key[i]] = obj[key[i]]
    }
    return newObj
}

// 参数序列化
function dataSerialize(sortObj:any) {
    let strJoin = ''
    for (let key in sortObj) {
        // strJoin += key + "=" + sortObj[key] + "&"
        if (sortObj[key] == '' || sortObj[key] == 'null') {
            //
        } else if (Object.prototype.toString.call(sortObj[key]) === '[object Object]') {

            for (let i in sortObj[key]) {
                strJoin += key + sortObj[key][i] + ','
            }
        } else if (Object.prototype.toString.call(sortObj[key]) === '[object Array]') {
            if (Object.prototype.toString.call(sortObj[key][0]) === '[object Object]'){
                strJoin +=key
                for (const val of sortObj[key]) {
                    console.log(val) // 输出{ name: 'lx' }, { age: 23 }
                    for (const k in val) {
                        console.log(val[k]) // 输出 lx,23
                        strJoin += k + val[k]
                    }
                }
            }else{
                strJoin += key + sortObj[key]
            }



        } else {

            strJoin += key + sortObj[key]
        }
        console.log(sortObj[key], 'lvjg sortObj[key]');

    }
    console.log(strJoin, 'lvjg strJoin ----');


    // return strJoin.substring(0, strJoin.length - 1)
    return strJoin
}
