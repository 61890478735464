import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3SeamlessScroll from 'vue3-seamless-scroll';
import 'lib-flexible/flexible'
import '@/assets/images/font_4srlzdijcp5/iconfont.css'
import '@/assets/images/font_4srlzdijcp5/iconfont.js'
import '@/assets/images/font_4srlzdijcp5/icon.css'

import dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/zh-cn' // import locale

dayjs.extend(isLeapYear) // use plugin
dayjs.extend(relativeTime) // use plugin
dayjs.locale('zh-cn') //
createApp(App).use(store).use(router).use(vue3SeamlessScroll).mount('#app')
