import axios from "@/api";

// 获取openid
const openid= (data) => {
  return axios("/user/getopenid", "POST", {
    data
  });
};
// 账号信息
const  userMobile= (data) => {
  return axios("/user/mobile", "POST", {
    data
  });
};

// 靓号推荐
const numberStarts= (params) => {
  return axios("/number/starts", "GET", {
    params
  });
};
// 靓号
const numberNicetype= (params) => {
  return axios("/number/nicetype", "GET", {
    params
  });
};
// 更多靓号
const moreNumber= (data) => {
  return axios("/number/morenumber", "POST", {
    data
  });
};
// 搜索号码
const numberSearch= (params) => {
  return axios(`/number/search?${params}`, "GET", );
};


//  换一批 靓号
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const numberBatch= (params) => {
  return axios(`/number/numberbatch/`, "GET", {
    params
  });
};
//  选择套餐
const productProList= (params) => {
  return axios(`/product/proList/${params}`, "GET", 
    );
};
// 落地页
const numberSaleKicked= () => {
  return axios(`/number/sale-kicked`, "GET", 
    );
};
// 落地页 - 分类号
const numberPromotional= () => {
  return axios(`/number/promotional`, "GET", 
    );
};
// 落地页 - 更多
const numberMore= (data) => {
  return axios(`/number/more`, "POST", {data}
    );
};

// //  所有资源
// const orderCentrex= (params) => {
//   return axios("/order/centrex/22", "GET", {
//     params
//   });
// };

// 验证码
const  sms= (data) => {
  return axios(`/sms/sendcode`, "POST",{data});
};
// 校验验证码
const  smsCheckcode= (data) => {
  return axios(`/sms/checkcode`, "POST",{data});
};
//  下单 支付
const productBuy= (data) => {
  return axios("/product/buy", "POST", {
    data
  });
};
//  支付成功测试
const orderNotify= (params) => {
  return axios("/order/notify", "GET", {
    params
  });
};
// 套餐  选号
const  productPackage= (data) => {
  return axios(`/product/package`, "POST",{data});
};
// 号码
const  numberPackage= (data) => {
  return axios(`/number/package`, "POST",{data});
};
// 二维码支付
const  nativePay= (data) => {
  return axios(`/product/native-pay`, "POST",{data});
};
 // 中间号
 const  centerNumber= (data) => {
  return axios(`/newbusiness/bind-centre-number`, "POST",{data});
};
 // 隐私号
 const  privacyNumber= (data) => {
  return axios(`/newbusiness/bind-privacy-number `, "POST",{data});
};
 // trial号码
 const  trialNumber= (params) => {
  return axios(`/newbusiness/numbers/${params}`, "GET",);
};
 // trialPay支付
 const  trialPay= (data) => {
  return axios(`/newbusiness/pay`, "POST",{data});
};

export {
  openid,
  userMobile,
  sms,
  smsCheckcode,
  numberStarts,
  numberNicetype,
  numberSearch,
  moreNumber,
  numberBatch,
  numberSaleKicked,
  numberPromotional,
  numberMore,
  productBuy,
  productProList,
  orderNotify,
  productPackage,
  numberPackage,
  nativePay,
  centerNumber,
  privacyNumber,
  trialNumber,
  trialPay
   
};
