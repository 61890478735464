import axios from "axios";
// import NProgress from 'nprogress'; // 进度条
import {Toast} from 'vant'
import { signatureGenerate } from "@/utils/signatureUtil";
// import { useStore } from "vuex";
const $axios = axios.create({
  baseURL: "/dscagent/huying/api",//39.105.170.239
  timeout: 20000,
  headers: {  'contentType': 'application/json; charset=utf-8'},
});
// 添加请求拦截器
$axios.interceptors.request.use(
 (config)=> {
  const { signature, timestamp, secret } = signatureGenerate(config);
  // 分别将签名、密钥、时间戳 至请求头
  if (signature) config.headers["Sign"] = signature;
  if (secret) config.headers["key"] = secret;
  if (timestamp) config.headers["timestamp"] = timestamp;
    localStorage.getItem('token') ? config.headers['token'] = localStorage.getItem('token') : '' 
    // if(store.state.token && store.state.token ){
    //   config.headers.token=store.state.token
    // }
    // NProgress.start() // 加载进度条开始
    return config;
  },
 (error)=> {
  if(error.message.includes('timeout')){   // 判断请求异常信息中是否含有超时timeout字符串
    Toast({message:"请求超时，请稍后再试",className:'toast'});
    return Promise.reject(error);          // reject这个错误信息
  }
  Toast({message:"网络链接失败，请稍后再试",className:'toast'});
    return Promise.reject(error);
  }
);

// 添加响应拦截器
$axios.interceptors.response.use(
  function (response) {
    // NProgress.done() // 加载进度条结束
    // console.log(response,'请求成功');
    return response.data;
  },
  function (error) {
    
    return Promise.reject(error);
  }
);
export default (url, method = 'GET', data) => {
  // const obj = {}

  // obj[['PUT', 'POST', 'PATCH'].includes(method.toUpperCase()) ? 'data' : 'params'] = data

  return $axios({
    url,
    method,
    ...data
  })
}
